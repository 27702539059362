.impactLayout {
    width: auto;
    height: auto;
    top: 13581px;
    left: 34221px;
    background: #FFFFFF;
    @include devices(mobile) {
        width: auto;
        height: auto;
        top: 13581px;
        left: 35788px;
    }
}
.impactPage {
    background: url('../../assets/impactSectionBg.svg');
    background-repeat: no-repeat;
    background-size: cover;
  }
  
  @media (max-width: 768px) {
    .impactPage {
      background: url('../../assets/impactSectionMweb.svg');
      background-repeat: no-repeat;
      background-size: cover;
    }
  }
  
  .impactPage__container {
    display: grid;
    max-width: 80%;
    margin: 0 auto;
  }
  
  @media (max-width: 768px) {
    .impactPage__container {
      max-width: 86vw;
      grid-template-columns: none;
      text-align: center;
    }
  }
  
  .impactPage__content {
    margin-top: 5.5rem;
    margin-bottom: 5.6rem;
  }
  
  @media (max-width: 768px) {
    .impactPage__content {
      margin-top: 3.6rem;
      margin-bottom: 0rem;
    }
  
    .impactPage__content button {
      width: 100%;
      margin-top: 1.6rem;
    }
  }
  
  .impactPage__mainSubheading {
    font-weight: 200;
    font-size: 2.4rem;
    line-height: 3.1rem;
    padding: 10px;
    color: #FFFFFF;
    position: relative;
  }
  
  .impactPage__mainSubheading::before {
    display: inline-block;
    position: absolute;
    content: '';
    border-top: 2px solid #ffffff;
    width: min(10vw, 5rem);
    margin-right: 0.8rem;
    transform: translateY(-0.5rem);
    position: relative;
  }
  
  @media (max-width: 768px) {
    .impactPage__mainSubheading {
      font-size: 1.8rem;
      line-height: 2.1rem;
      text-align: center;
    }
  
    .impactPage__mainSubheading::after {
      display: inline-block;
      content: '';
      border-top: 2px solid #ffffff;
      width: min(10vw, 5rem);
      margin-left: 0.8rem;
      transform: translateY(-0.5rem);
    }
  }
  
  .impactPage__heading {
    font-weight: 500;
    font-size: 3.2rem;
    line-height: 4.4rem;
    color: #FFFFFF;
    padding: 10px;
  }
  
  @media (max-width: 768px) {
    .impactPage__heading {
      text-align: center;
      font-size: 24px;
      line-height: 3rem;
    }
  }
  
  .impact-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-top: 20px;
  }
  
  .impact-card {
    color: #000;
    margin: 10px;
    width: 240px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .impact-percentage {
    background-color: #fff;
    border-radius: 40px;
    font-size: 24px;
    width: 240px;
    font-weight: 600;
    margin-bottom: 20px;
  }
  
  .impact-text {
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 20px;
    color: #fff;
    height: auto; 
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    white-space: pre-line;
  }
  
  .impact-image-wrapper {
    position: relative;
    width: 230px; 
    height: 230px; 
    margin-bottom: 10px;
  }
  
  .impact-image-wrapper::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    z-index: 0;
    transform: scale(1.2);
  }
  
  .impact-image-wrapper img {
    position: relative;
    width: 100%;
    height: 80%;
    border-radius: 50%;
    z-index: 1;
  }
  
  @media (max-width: 768px) {
    .impact-cards {
      flex-direction: column;
      align-items: center;
    }
  
    .impact-card {
      width: 80%;
    }
  
    .impact-image-wrapper {
      width: 120px; 
      height: 120px; 
    }
  }
  